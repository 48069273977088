import axios from 'axios';
import {
    FETCH_DATA,
    SET_IS_MODAL_OPEN,
    BASE_PATH,
    SET_STATUS,
    FETCH_TEXT, SERVICE_ALIAS,
} from '../constants/index';

export const fetchData = () => async (dispatch) => axios.get(`${BASE_PATH}/api/compact-auto`)
    .then((res) => dispatch({
        type: FETCH_DATA,
        payload: res.data,
    }))
    .catch(() => {});

export const fetchText = (alias) => async (dispatch) => axios.get(`${BASE_PATH}/text/${SERVICE_ALIAS}/${alias}/1`)
    .then((res) => dispatch({
        type: FETCH_TEXT,
        payload: {
            alias,
            text: res.data,
        },
    }))
    .catch(() => {});

export const setModalIsOpen = (state, contentId = null) => ({
    type: SET_IS_MODAL_OPEN,
    payload: {
        isModalOpen: state,
        modalContentId: contentId,
    },
});

export const getStatus = () => async (dispatch) => axios.get(`${BASE_PATH}/api/status`)
    .then((res) => {
        if (res.status === 200 && res.data) {
            dispatch({
                type: SET_STATUS,
                payload: res.data,
            });
        }
    })
    .catch(() => {});
