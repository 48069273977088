import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HomePage from '../../pages/HomePage';
import ErrorPage from '../../pages/ErrorPage';
import TextPage from '../../pages/TextPage';

const Routes = () => (
    <Switch>
        <Route path="/user/conditions" render={() => <TextPage alias="oferta" />} />
        <Route path="/privacypolicy" render={() => <TextPage alias="privacypolicy" />} />
        <Route path="/" exact component={HomePage} />
        <Route component={ErrorPage} />
    </Switch>
);

export default Routes;
