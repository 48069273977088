import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { PrivacyPolicy } from '@b3w/pp-portal';
import '@b3w/pp-portal/dist/portal-bundle.css';
import { Statistic } from '@b3w/react-statistic';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Routes from '../Routes/Routes';
import { BASE_PATH, SERVICE_ALIAS } from '../../constants';
import { fetchData, getStatus } from '../../actions/actionsContent';
import './App.sass';
import AddToHomeScreen from '../AddToHomeScreen/AddToHomeScreen';
import useFirebase from '../../hooks/useFirebase';

const App = () => {
    const dispatch = useDispatch();
    const [ppText, setPpText] = useState('');
    const [supportText, setSupportText] = useState('');

    useFirebase();

    useEffect(() => {
        dispatch(fetchData()).catch(() => {});
        dispatch(getStatus()).catch(() => {});

        axios.get(`${BASE_PATH}/text/${SERVICE_ALIAS}/privacyfooter/1`)
            .then((res) => {
                if (res.data) setPpText(res.data);
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        axios.get(`${BASE_PATH}/text/${SERVICE_ALIAS}/header-support/1`)
            .then((res) => {
                setSupportText(res.data);
            });
    }, []);

    return (
        <>
            <Statistic
                path={`${BASE_PATH}/referer/statistic`}
                timeoutMinutes={1}
                from
            />
            {/* eslint-disable-next-line react/no-danger */}
            <div className="HeaderSupport" dangerouslySetInnerHTML={{ __html: supportText }} />
            {ppText && <PrivacyPolicy dangerousText={ppText} adoption />}
            <Header />
            <Routes />
            <Footer />
            <AddToHomeScreen />
        </>
    );
};

export default App;
