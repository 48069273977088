import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cn } from '@bem-react/classname';
import { Link } from 'react-router-dom';
import { fetchText } from '../../actions/actionsContent';

import './Footer.sass';

const Footer = () => {
    const dispatch = useDispatch();
    const cnFooter = cn('Footer');
    const footer = useSelector((state) => state.texts.footer);

    useEffect(() => {
        dispatch(fetchText('footer')).catch(() => {});
    }, []);

    return (
        <div className={cnFooter()}>
            <div className={cnFooter('LinksWrapper')}>
                <Link className={cnFooter('Link')} to="/user/conditions">
                    Пользовательское соглашение
                </Link>
                <Link className={cnFooter('Link')} to="/privacypolicy">
                    Политика обработки персональный данных
                </Link>
            </div>
            {/* eslint-disable-next-line react/no-danger */}
            <div className={cnFooter('Text')} dangerouslySetInnerHTML={{ __html: footer }} />
        </div>
    );
};

export default Footer;
