import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cn } from '@bem-react/classname';
import { fetchText } from '../actions/actionsContent';

const TextPage = ({ alias }) => {
    const cnTextPage = cn('TextPage');
    const dispatch = useDispatch();
    const texts = useSelector((state) => state.texts);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!texts[alias]) dispatch(fetchText(alias)).catch(() => {});
    }, [alias]);

    return (
        <main>
            <div
                className={cnTextPage('Wrapper')}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: texts[alias] }}
            />
        </main>
    );
};

export default TextPage;
